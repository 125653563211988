import { render, staticRenderFns } from "./SdsSection9Form.vue?vue&type=template&id=6f490ea0&scoped=true"
import script from "./SdsSection9Form.vue?vue&type=script&setup=true&lang=ts"
export * from "./SdsSection9Form.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SdsSection9Form.vue?vue&type=style&index=0&id=6f490ea0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f490ea0",
  null
  
)

export default component.exports
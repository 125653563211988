
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { NavigationGuardNext, Route } from 'vue-router';
import { StatusId } from '@/lib/enum';
import { pdfDisplayOptions, statusList } from '@/lib/formItem';
import { store } from '@/store/index';
import {
  dispatchCreateReanalysisSds,
  dispatchDeleteCompanySdses,
  dispatchGetCompanySds,
  dispatchGetCompanySdsPdf,
  dispatchGetCompanySdses,
  dispatchGetCompanySdsesCount,
  dispatchUpdateReconfirmationSds,
} from '@/store/main/actionsMain';
import { readCompany } from '@/store/main/getters';
import { commitSetCompany } from '@/store/main/mutations';
import SdsBottomSheet from '@/views/components/views/dashboard/bottomSheet/SdsBottomSheet.vue';
import CompanySdsTable from '@/views/components/views/dashboard/CompanySdsTableReconfirmation.vue';
import PdfViewer from '@/views/components/views/dashboard/PdfViewer.vue';
import SdsTableDrawer from '@/views/components/views/dashboard/SdsTableDrawer.vue';
import { useDepartmentComposable } from '@/views/composables/main/general/useDepartment';
import { ReconfirmationSds, SdsResponse } from '@/interfaces/api/general/sds';
import { Department } from '@/interfaces/model/general/department';
import { TagResponse } from '@/interfaces/model/general/tag';

const DefaultReconfirmationSds: ReconfirmationSds = {
  check: false,
  comment: '',
  comment_created_at: '',
  departments: [],
  tags: [],
  created_user: null,
  dynamic_list_result: [],
  sds_section8_list: [],
  sds_section14: {
    id: 0,
    sds_id: '',
    un_number: '',
    created_at: '',
    updated_at: '',
    deleted_at: null,
  },
  sds_products: [],
  sds_type: {
    id: 0,
    sds_id: '',
    language_id: 0,
    is_embedded: false,
    fee: 0,
    comment: '',
    created_at: '',
    updated_at: '',
    deleted_at: null,
  },
  assistant_log_cross_checks: [],
  id: 0,
  uuid: '',
  replaced_name: '',
  name: '',
  note: '',
  admin_note: '',
  status_id: 0,
  timestamp: 0,
  created_by: '',
  updated_by: '',
  company_id: '',
  published_at: null,
  revised_at: null,
  supplier_confirmed_at: null,
  assessed_at: null,
  created_at: '',
  deleted_at: null,
  updated_at: '',
};

export default defineComponent({
  components: {
    SdsBottomSheet,
    CompanySdsTable,
    SdsTableDrawer,
    PdfViewer,
  },
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): void {
    if (this.isEditing) {
      const isAllowed: boolean = window.confirm(
        '編集中の作業は保存されませんが、よろしいですか？'
      );
      if (isAllowed) {
        next();
      }
    } else {
      next();
    }
  },
  setup() {
    const allSdsCheck = ref<boolean>(false);
    const showSdsDetailView = ref<boolean>(false);
    const selectedPdfDisplayOption = ref<string>('none');
    const pdfBlob = ref<string>('');
    const pdfName = ref<string>('');
    const reconfirmationSds = ref<ReconfirmationSds[]>([]); // SDS一覧表示用データ
    const activeSds = ref<ReconfirmationSds>(DefaultReconfirmationSds);
    const keywordList = ref<string[]>([]);
    const total = ref<number>(0);
    const page = ref<number>(1);
    const limit = ref<number>(50);
    const productFormItems = ref<Array<{ text: string; value: number }>>([]);
    const currentIndex = ref(0);
    const isLoadingSdses = ref<boolean>(false);
    const isLoadingSds = ref<boolean>(false);
    const isEditing = ref<boolean>(false);
    const reanalysisSdsModalShow = ref<boolean>(false);
    const reanalysisSdsComment = ref<{
      comment: string;
      sds_id: string;
    }>({
      comment: '',
      sds_id: '',
    });
    const { getCompanyDepartments, companyDepartments } =
      useDepartmentComposable();

    watch(allSdsCheck, (next) => {
      reconfirmationSds.value.forEach((row) => (row.check = next));
    });

    watch(selectedPdfDisplayOption, (next) => {
      if (next === 'none') {
        activeSds.value = DefaultReconfirmationSds;
      }
    });

    watch(showSdsDetailView, (next) => {
      if (next === false) {
        activeSds.value = DefaultReconfirmationSds;
      }
    });

    const selectedSdses = computed(() => {
      return reconfirmationSds.value.filter((row) => row.check === true);
    });

    const bindSdsData = (item: ReconfirmationSds): void => {
      activeSds.value = item;
    };

    const resetPagenation = (): void => {
      page.value = 1;
    };

    const resetSearchForm = (): void => {
      keywordList.value = [];
    };

    const hasPrevSds = computed((): boolean => {
      return currentIndex.value > 0;
    });

    const hasNextSds = computed((): boolean => {
      return currentIndex.value < reconfirmationSds.value.length - 1;
    });

    const showPrevSds = async (): Promise<void> => {
      const index = currentIndex.value - 1;

      showSdsDetail(reconfirmationSds.value[index], index);
    };

    const showNextSds = async (): Promise<void> => {
      const index = currentIndex.value + 1;

      showSdsDetail(reconfirmationSds.value[index], index);
    };

    const showSdsDetail = async (
      item: ReconfirmationSds,
      index: number
    ): Promise<void> => {
      // 一旦ビューを出す
      showSdsDetailView.value = true;
      isLoadingSds.value = true;

      await fetchCompanySds(item.uuid);
      await fetchPdfData(item.uuid);

      selectedPdfDisplayOption.value = 'none';
      currentIndex.value = index;

      productFormItems.value.splice(0);
      productFormItems.value.push(
        ...item.sds_products.map((x, idx) => {
          return {
            text: x.name,
            value: idx,
          };
        })
      );

      isLoadingSds.value = false;
    };

    const showPdf = async (item: ReconfirmationSds): Promise<void> => {
      await fetchPdfData(item.uuid);

      selectedPdfDisplayOption.value = 'max';
      showSdsDetailView.value = false;
      pdfName.value = item.name;
      activeSds.value = item;
    };

    const pdpageScalefApp = computed((): string => {
      return selectedPdfDisplayOption.value == 'max'
        ? 'page-width'
        : 'page-fit';
    });

    const fetchPdfData = async (fileUuid: string): Promise<void> => {
      const res = await dispatchGetCompanySdsPdf(store, {
        companyId: store.state.main.company.uuid,
        fileUuid: fileUuid,
      });
      if (res) {
        pdfBlob.value = `data:application/pdf;base64,${res.data.data}`;
      }
    };

    const createReconfirmationSds = (sds: SdsResponse): ReconfirmationSds => {
      // ステータスに応じて表示するコメント情報を切り替える
      let comment = '';
      let comment_created_at = '';
      if (
        sds.status_id === StatusId.ERROR.value &&
        sds.assistant_log_cannot_analysis &&
        sds.assistant_log_cannot_analysis.length > 0
      ) {
        const recentCannotAnalysis =
          sds.assistant_log_cannot_analysis.reverse()[0];
        comment = recentCannotAnalysis.comment;
        comment_created_at = recentCannotAnalysis.created_at;
      } else if (
        sds.status_id === StatusId.ERROR_RECONFIRMATION.value &&
        sds.assistant_log_reconfirmation &&
        sds.assistant_log_reconfirmation.length > 0
      ) {
        const recentReconfirmation =
          sds.assistant_log_reconfirmation.reverse()[0];
        comment = recentReconfirmation.comment;
        comment_created_at = recentReconfirmation.created_at;
      }

      return {
        ...sds,
        sds_section14: sds.sds_section14 || {
          id: 0,
          sds_id: '',
          un_number: '',
          created_at: '',
          updated_at: '',
          deleted_at: null,
        }, // TODO:nullが返ってくることがあるが、型を直すと影響が大きすぎるため初期値設定としている
        check: false,
        comment: comment,
        comment_created_at: comment_created_at,
      };
    };

    const fetchCompanySds = async (sdsId: string): Promise<void> => {
      const res = await dispatchGetCompanySds(store, {
        sdsId: sdsId,
        companyId: store.state.main.company.uuid,
      });

      if (res) {
        activeSds.value.tags = res.data.sds.tags;
        activeSds.value.id = res.data.sds.id;
        activeSds.value.uuid = res.data.sds.uuid;
        activeSds.value.name = res.data.sds.name;
        activeSds.value.replaced_name = res.data.sds.replaced_name;
        activeSds.value.note = res.data.sds.note;
        activeSds.value.admin_note = res.data.sds.admin_note;
        activeSds.value.company_id = res.data.sds.company_id;
        activeSds.value.departments = res.data.sds.departments;
        activeSds.value.status_id = res.data.sds.status_id;
        activeSds.value.updated_by = res.data.sds.updated_by;
        activeSds.value.timestamp = res.data.sds.timestamp;
        activeSds.value.published_at = res.data.sds.published_at;
        activeSds.value.revised_at = res.data.sds.revised_at;
        activeSds.value.created_at = res.data.sds.created_at;
        activeSds.value.updated_at = res.data.sds.updated_at;
        activeSds.value.supplier_confirmed_at =
          res.data.sds.supplier_confirmed_at;
        activeSds.value.deleted_at = res.data.sds.deleted_at;
        activeSds.value.assessed_at = res.data.sds.assessed_at;
        activeSds.value.sds_section14 = res.data.sds.sds_section14;
        activeSds.value.sds_section8_list = res.data.sds.sds_section8_list;
        activeSds.value.dynamic_list_result = res.data.sds.dynamic_list_result;
        activeSds.value.sds_products = res.data.sds.sds_products.map((x) => {
          return {
            id: x.id,
            sds_id: x.sds_id,
            name: x.name,
            created_at: x.created_at,
            updated_at: x.updated_at,
            deleted_at: x.deleted_at,
            sds_section1_importers: x.sds_section1_importers.map((im) => {
              return {
                id: im.id,
                sds_id: im.sds_id,
                sds_product_id: im.sds_product_id,
                name: im.name,
                phone: im.phone,
                address: im.address,
                email: im.email,
                created_at: im.created_at,
                updated_at: im.updated_at,
                deleted_at: im.deleted_at,
              };
            }),
            sds_section1_manufacturers: x.sds_section1_manufacturers.map(
              (im) => {
                return {
                  id: im.id,
                  sds_id: im.sds_id,
                  sds_product_id: im.sds_product_id,
                  name: im.name,
                  phone: im.phone,
                  address: im.address,
                  email: im.email,
                  created_at: im.created_at,
                  updated_at: im.updated_at,
                  deleted_at: im.deleted_at,
                };
              }
            ),
            sds_section2_list: x.sds_section2_list.sort((a, b) =>
              a.item_id > b.item_id ? 1 : -1
            ),
            sds_section2_label: {
              id: x.sds_section2_label.id,
              sds_id: x.sds_section2_label.sds_id,
              sds_product_id: x.sds_section2_label.sds_product_id,
              bomb: x.sds_section2_label.bomb,
              fire: x.sds_section2_label.fire,
              fire_circle: x.sds_section2_label.fire_circle,
              gas: x.sds_section2_label.gas,
              corrosion: x.sds_section2_label.corrosion,
              toxic: x.sds_section2_label.toxic,
              exclamation: x.sds_section2_label.exclamation,
              health: x.sds_section2_label.health,
              environment: x.sds_section2_label.environment,
              signal_words: x.sds_section2_label.signal_words,
              created_at: x.sds_section2_label.created_at,
              updated_at: x.sds_section2_label.updated_at,
              deleted_at: x.sds_section2_label.deleted_at,
            },
            sds_section3: {
              id: x.sds_section3.id,
              sds_id: x.sds_section3.sds_id,
              sds_product_id: x.sds_section3.sds_product_id,
              created_at: x.sds_section3.created_at,
              updated_at: x.sds_section3.updated_at,
              deleted_at: x.sds_section3.deleted_at,
              composition_id: x.sds_section3.composition_id,
              material_list: x.sds_section3.material_list.map((s3) => {
                return {
                  id: s3.id,
                  sds_id: s3.sds_id,
                  sds_section3_id: s3.sds_section3_id,
                  material_name: s3.material_name,
                  concentration: s3.concentration,
                  encs_number: s3.encs_number,
                  ishl_number: s3.ishl_number,
                  is_stated: s3.is_stated,
                  concentration_inequality_sign:
                    s3.concentration_inequality_sign,
                  concentration_min: s3.concentration_min,
                  concentration_max: s3.concentration_max,
                  concentration_unit: s3.concentration_unit,
                  created_at: s3.created_at,
                  updated_at: s3.updated_at,
                  deleted_at: s3.deleted_at,
                  cas_list: s3.cas_list.map((cas) => {
                    return {
                      id: cas.id,
                      sds_id: cas.sds_id,
                      sds_section3_material_id: cas.sds_section3_material_id,
                      cas_number: cas.cas_number,
                      created_at: cas.created_at,
                      updated_at: cas.updated_at,
                      deleted_at: cas.deleted_at,
                    };
                  }),
                };
              }),
            },
            sds_section8_acgih_list: x.sds_section8_acgih_list.map(
              (section8) => {
                return {
                  id: section8.id,
                  sds_id: section8.sds_id,
                  sds_product_id: section8.sds_product_id,
                  category_id: section8.category_id,
                  concentration: section8.concentration,
                  concentration_unit: section8.concentration_unit,
                  concentration_note: section8.concentration_note,
                  amount: section8.amount,
                  amount_unit: section8.amount_unit,
                  amount_note: section8.amount_note,
                  other: section8.other,
                  created_at: section8.created_at,
                  updated_at: section8.updated_at,
                  deleted_at: section8.deleted_at,
                  material_list: section8.material_list.map((material) => {
                    return {
                      id: material.id,
                      sds_id: material.sds_id,
                      sds_section8_acgih_id: material.sds_section8_acgih_id,
                      material_name: material.material_name,
                      created_at: material.created_at,
                      updated_at: material.updated_at,
                      deleted_at: material.deleted_at,
                      cas_list: material.cas_list.map((cas) => {
                        return {
                          id: cas.id,
                          sds_id: cas.sds_id,
                          sds_section8_acgih_material_id:
                            cas.sds_section8_acgih_material_id,
                          cas_number: cas.cas_number,
                          created_at: cas.created_at,
                          updated_at: cas.updated_at,
                          deleted_at: cas.deleted_at,
                        };
                      }),
                    };
                  }),
                };
              }
            ),
            sds_section8_jsoh_list: x.sds_section8_jsoh_list.map((section8) => {
              return {
                id: section8.id,
                sds_id: section8.sds_id,
                sds_product_id: section8.sds_product_id,
                category_concentration_id: section8.category_concentration_id,
                category_dust_id: section8.category_dust_id,
                category_dust_respirable_id:
                  section8.category_dust_respirable_id,
                concentration: section8.concentration,
                concentration_unit: section8.concentration_unit,
                concentration_note: section8.concentration_note,
                amount: section8.amount,
                amount_unit: section8.amount_unit,
                amount_note: section8.amount_note,
                other: section8.other,
                created_at: section8.created_at,
                updated_at: section8.updated_at,
                deleted_at: section8.deleted_at,
                material_list: section8.material_list.map((material) => {
                  return {
                    id: material.id,
                    sds_id: material.sds_id,
                    sds_section8_jsoh_id: material.sds_section8_jsoh_id,
                    material_name: material.material_name,
                    created_at: material.created_at,
                    updated_at: material.updated_at,
                    deleted_at: material.deleted_at,
                    cas_list: material.cas_list.map((cas) => {
                      return {
                        id: cas.id,
                        sds_id: cas.sds_id,
                        sds_section8_jsoh_material_id:
                          cas.sds_section8_jsoh_material_id,
                        cas_number: cas.cas_number,
                        created_at: cas.created_at,
                        updated_at: cas.updated_at,
                        deleted_at: cas.deleted_at,
                      };
                    }),
                  };
                }),
              };
            }),
            sds_section8_dnel_dmel_list: x.sds_section8_dnel_dmel_list.map(
              (section8) => {
                return {
                  id: section8.id,
                  sds_id: section8.sds_id,
                  sds_product_id: section8.sds_product_id,
                  category_user_id: section8.category_user_id,
                  category_term_id: section8.category_term_id,
                  category_dust_route_id: section8.category_dust_route_id,
                  category_measurement_id: section8.category_measurement_id,
                  concentration: section8.concentration,
                  concentration_unit: section8.concentration_unit,
                  concentration_note: section8.concentration_note,
                  amount: section8.amount,
                  amount_unit: section8.amount_unit,
                  amount_note: section8.amount_note,
                  other: section8.other,
                  created_at: section8.created_at,
                  updated_at: section8.updated_at,
                  deleted_at: section8.deleted_at,
                  material_list: section8.material_list.map((material) => {
                    return {
                      id: material.id,
                      sds_id: material.sds_id,
                      sds_section8_dnel_dmel_id:
                        material.sds_section8_dnel_dmel_id,
                      material_name: material.material_name,
                      created_at: material.created_at,
                      updated_at: material.updated_at,
                      deleted_at: material.deleted_at,
                      cas_list: material.cas_list.map((cas) => {
                        return {
                          id: cas.id,
                          sds_id: cas.sds_id,
                          sds_section8_dnel_dmel_material_id:
                            cas.sds_section8_dnel_dmel_material_id,
                          cas_number: cas.cas_number,
                          created_at: cas.created_at,
                          updated_at: cas.updated_at,
                          deleted_at: cas.deleted_at,
                        };
                      }),
                    };
                  }),
                };
              }
            ),
            sds_section9_list: x.sds_section9_list.map((s9) => {
              return {
                id: s9.id,
                sds_id: s9.sds_id,
                sds_product_id: s9.sds_product_id,
                condition: s9.condition,
                flash_point: s9.flash_point,
                physical_condition: s9.physical_condition,
                form: s9.form,
                measurement_method: s9.measurement_method,
                created_at: s9.created_at,
                updated_at: s9.updated_at,
                deleted_at: s9.deleted_at,
              };
            }),
            sds_section9_flash_point_list: x.sds_section9_flash_point_list.map(
              (f) => {
                return {
                  id: f.id,
                  sds_id: f.sds_id,
                  sds_product_id: f.sds_product_id,
                  flash_point_min: f.flash_point_min,
                  flash_point_max: f.flash_point_max,
                  flash_point_inequality_sign: f.flash_point_inequality_sign,
                  unit_id: f.unit_id,
                  flash_point: f.flash_point,
                  measurement_method: f.measurement_method,
                  note: f.note,
                  created_at: f.created_at,
                  updated_at: f.updated_at,
                  deleted_at: f.deleted_at,
                };
              }
            ),
            sds_section9_boiling_point_list:
              x.sds_section9_boiling_point_list.map((f) => {
                return {
                  id: f.id,
                  sds_id: f.sds_id,
                  sds_product_id: f.sds_product_id,
                  boiling_point_min: f.boiling_point_min,
                  boiling_point_max: f.boiling_point_max,
                  boiling_point_inequality_sign:
                    f.boiling_point_inequality_sign,
                  unit_id: f.unit_id,
                  boiling_point: f.boiling_point,
                  measurement_method: f.measurement_method,
                  note: f.note,
                  created_at: f.created_at,
                  updated_at: f.updated_at,
                  deleted_at: f.deleted_at,
                };
              }),
            sds_section15_list: x.sds_section15_list.map((section15) => {
              return {
                id: section15.id,
                sds_id: section15.sds_id,
                sds_product_id: section15.sds_product_id,
                law_id: section15.law_id,
                regulation1_id: section15.regulation1_id,
                regulation2_id: section15.regulation2_id,
                regulation3_id: section15.regulation3_id,
                regulation4_id: section15.regulation4_id,
                regulation5_id: section15.regulation5_id,
                applicable: section15.applicable,
                text: section15.text,
                created_at: section15.created_at,
                updated_at: section15.updated_at,
                deleted_at: section15.deleted_at,
                material_list: section15.material_list.map((material) => {
                  return {
                    id: material.id,
                    sds_id: material.sds_id,
                    sds_section15_id: material.sds_section15_id,
                    material_name: material.material_name,
                    created_at: material.created_at,
                    updated_at: material.updated_at,
                    deleted_at: material.deleted_at,
                    cas_list: material.cas_list.map((cas) => {
                      return {
                        id: cas.id,
                        sds_id: cas.sds_id,
                        sds_section15_material_id:
                          cas.sds_section15_material_id,
                        cas_number: cas.cas_number,
                        created_at: cas.created_at,
                        updated_at: cas.updated_at,
                        deleted_at: cas.deleted_at,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        });
      }

      if (res) {
        bindSdsData(createReconfirmationSds(res.data.sds));
      }
    };

    const fetchCompanySdses = async (): Promise<void> => {
      reconfirmationSds.value.splice(0);

      const res = await dispatchGetCompanySdses(store, {
        companyId: store.state.main.company.uuid,
        limit: limit.value,
        page: page.value - 1,
        keywordList: keywordList.value,
        statusIdList: [
          StatusId.ERROR.value,
          StatusId.ERROR_RECONFIRMATION.value,
        ],
        sortState: { status: 'desc' }, // 要確認を先頭に表示する
        userIdList: [],
        tagIdList: [],
        departmentIdList: [],
        sdsName: '',
        productName: '',
        revisedAtStartDate: '',
        revisedAtEndDate: '',
        createdAtStartDate: '',
        createdAtEndDate: '',
        hasProduct: true,
        hasSection1: false,
        hasSection2: false,
        hasSection3: true,
        hasSection8: false,
        hasSection8Acgih: false,
        hasSection8Jsoh: false,
        hasSection8DnelDmel: false,
        hasSection9: false,
        hasSection14: true,
        hasSection15: false,
        hasAssistantLogCannotAnalysis: true,
        hasAssistantLogReconfirmation: true,
      });
      if (res) {
        reconfirmationSds.value.splice(0);
        reconfirmationSds.value.push(
          ...res.data.sdses.map((sds) => createReconfirmationSds(sds))
        );
      }
    };

    const fetchCompanySdsesCountWithCondition = async (): Promise<void> => {
      isLoadingSdses.value = true;

      const res = await dispatchGetCompanySdsesCount(store, {
        companyId: store.state.main.company.uuid,
        keywordList: keywordList.value,
        statusIdList: [
          StatusId.ERROR.value,
          StatusId.ERROR_RECONFIRMATION.value,
        ],
      });
      if (res) {
        total.value = res.data.total;
      }

      isLoadingSdses.value = false;
    };

    const fetchCompanySdsesCount = async (): Promise<void> => {
      const res = await dispatchGetCompanySdsesCount(store, {
        companyId: store.state.main.company.uuid,
        keywordList: keywordList.value,
      });
      if (res) {
        commitSetCompany(store, {
          ...readCompany(store),
          uploaded_file_number: res.data.total,
        });
      }
    };

    // 編集可能なSDS項目のみ更新する
    const doUpdateCompanySds = async (
      data: ReconfirmationSds,
      tags: TagResponse[],
      departments: Department[]
    ): Promise<void> => {
      await dispatchUpdateReconfirmationSds(store, {
        companyId: store.state.main.company.uuid,
        sdsId: data.uuid,
        form: {
          department_id_list: departments.map((v) => v.uuid),
          tag_id_list: tags.map((v) => v.id),
          note: data.note,
          assessed_at: data.assessed_at,
          supplier_confirmed_at: data.supplier_confirmed_at,
        },
      });

      await fetchCompanySdses();
      await fetchCompanySdsesCount();
      await fetchCompanySdsesCountWithCondition();
      await fetchCompanySds(data.uuid);
    };

    const deleteCompanySds = async (uuidList: Array<string>): Promise<void> => {
      const res = await dispatchDeleteCompanySdses(store, {
        companyId: store.state.main.company.uuid,
        form: {
          sds_uuid_list: uuidList,
        },
      });
      if (res) {
        await fetchCompanySdses();
        await fetchCompanySdsesCount();
        await fetchCompanySdsesCountWithCondition();
      }
    };

    const deleteSelectedSds = async (): Promise<void> => {
      const uuidList = selectedSdses.value.map((s) => s.uuid);
      await deleteCompanySds(uuidList);
    };

    const deleteSds = async (fileUuid: string): Promise<void> => {
      await deleteCompanySds([fileUuid]);
    };

    const confirmSave = (event): void => {
      if (isEditing.value) {
        event.preventDefault();
        event.returnValue = '編集中の作業は保存されませんが、よろしいですか？';
      }
    };

    const openReanalysisSdsModal = (sdsId: string): void => {
      reanalysisSdsModalShow.value = true;
      reanalysisSdsComment.value = {
        comment: '',
        sds_id: sdsId,
      };
    };
    const closeReanalysisSdsModal = (): void => {
      reanalysisSdsModalShow.value = false;
    };

    const reanalysisSds = async (): Promise<void> => {
      const payload = {
        sdsId: reanalysisSdsComment.value.sds_id,
        comment: reanalysisSdsComment.value.comment,
      };
      await dispatchCreateReanalysisSds(store, payload);
      closeReanalysisSdsModal();
      await fetchCompanySdses();
      await fetchCompanySdsesCount();
      await fetchCompanySdsesCountWithCondition();
      await fetchCompanySds(reanalysisSdsComment.value.sds_id);
    };

    onMounted(async (): Promise<void> => {
      window.onbeforeunload = confirmSave;
      await fetchCompanySdses();
      await fetchCompanySdsesCount();
      await fetchCompanySdsesCountWithCondition();
      await getCompanyDepartments();
    });

    return {
      keywordList,
      pdfBlob,
      pdfName,
      limit,
      total,
      page,
      statusList,
      allSdsCheck,
      pdfDisplayOptions,
      reconfirmationSds,
      isLoadingSdses,
      isLoadingSds,
      isEditing,
      showSdsDetailView,
      pdpageScalefApp,
      productFormItems,
      selectedPdfDisplayOption,
      showPrevSds,
      showNextSds,
      hasPrevSds,
      hasNextSds,
      showPdf,
      showSdsDetail,
      deleteSelectedSds,
      deleteSds,
      resetPagenation,
      resetSearchForm,
      fetchCompanySdses,
      fetchCompanySdsesCount,
      fetchCompanySdsesCountWithCondition,
      fetchCompanySds,
      fetchPdfData,
      doUpdateCompanySds,
      activeSds,
      selectedSdses,
      reanalysisSdsModalShow,
      openReanalysisSdsModal,
      closeReanalysisSdsModal,
      reanalysisSdsComment,
      reanalysisSds,
      companyDepartments,
    };
  },
});
